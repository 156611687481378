import React, { useState, useEffect } from "react";
import axios from "axios";
import SimpleReactLightbox from "simple-react-lightbox";
import ReactTooltip from "react-tooltip";
import Modal from "react-modal";
import Markdown from "markdown-to-jsx";
import Gallery from "react-photo-gallery";
import _ from "lodash";
import Social from "./Social";
import PropagateLoader from "react-spinners/PropagateLoader";
import constants from "../config/constants";
import {
  usePortfolioPage,
  usePortfolioPageUpdate,
} from "../contexts/portfolioPageContext";
import { useErrorUpdate } from "../contexts/errorContext";

const Wrapper = ({ data = [] }) => {
  // for modal
  const [isOpen, setIsOpen] = useState(false);
  const [itemOpen, setItemOpen] = useState(null);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  function setItem(item) {
    setItemOpen(item);
  }

  const projects = data.map((project) => {
    return (
      <li
        key={project.id}
        data-aos="fade-right"
        data-aos-duration="1200"
        data-aos-delay="200"
      >
        <div className="inner">
          <div className="entry tokyo_tm_portfolio_animation_wrap">
            <img
              src={`${constants.API_URL}${project.attributes.portfolioPlayHeadImage.data.attributes.formats.medium.url}`}
              alt="Details"
              data-tip
              data-for={`${project.id}`}
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                toggleModal();
                setItem(project);
              }}
            />

            <ReactTooltip
              id={`${project.id}`}
              place="bottom"
              type="light"
              effect="float"
              className="tooltip-wrapper"
            >
              <div>
                <h5>{project.attributes?.portfolioPlayName}</h5>
                <span>Details</span>
              </div>
            </ReactTooltip>
          </div>
        </div>
      </li>
    );
  });

  const teamInfo = (teamInfo = []) => {
    const team = teamInfo.map((member) => {
      return (
        <>
          <span key={member.id}>{member.portfolioPlayTeamMember}</span>
          <br></br>
        </>
      );
    });
    return team;
  };

  const prepareImageGallery = (imageGallery = []) => {
    const images = imageGallery.map((item) => {
      return {
        src: `${constants.API_URL}${item.attributes.url}`,
        width: item.attributes.width,
        height: item.attributes.height,
      };
    });
    return images;
  };

  return (
    <>
      <SimpleReactLightbox>
        <div className="tokyo_tm_portfolio">
          <div className="tokyo_tm_title">
            <div className="title_flex">
              <div className="left">
                <span>Portfolio</span>
                {/* <h3>Play list</h3> */}
              </div>
            </div>
          </div>
          {/* END TOKYO_TM_TITLE */}

          <div className="portfolio_filter">
            <div className="list_wrapper">
              <ul className="portfolio_list">
                {projects}
                {/* END DETAILS */}
              </ul>
              {/* END PORTFOLIO LIST */}
              {/* END ALL PORTFOLIO GALLERY */}
            </div>
            {/* END LIST WRAPPER */}
          </div>
        </div>
      </SimpleReactLightbox>

      {/* START MODAL FOR PORTFOLIO DETAILS */}
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          // eslint-disable-next-line no-unused-expressions
          toggleModal();
          setItem(null);
        }}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
        ariaHideApp={false}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModal}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: `url(
                      ${constants.API_URL}${itemOpen?.attributes?.portfolioPlayHeadImage?.data?.attributes?.url}
                      )`,
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>{itemOpen?.attributes?.portfolioPlayName ?? ""}</h3>
                {/* <span>Zhanr</span> */}
                {/* <br /> */}
                <span>{itemOpen?.attributes?.portfolioPlayDate ?? ""}</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <Markdown>
                    {itemOpen?.attributes?.portfolioPlayMainText ?? ""}
                  </Markdown>
                </div>
                {/* <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Place</span>
                      <span>{itemOpen?.attributes?.portfolioPlayPlace}</span>
                    </li>
                    <li>
                      <span className="first">Team</span>
                      {isOpen && teamInfo(itemOpen?.attributes?.portfolioPlayTeam)}
                    </li>
                    {itemOpen?.attributes?.portfolioPlayAwards && <li>
                      <span className="first">Awards</span>
                      <span>
                        <Markdown>
                          {itemOpen?.attributes?.portfolioPlayAwards}
                        </Markdown>
                      </span>
                    </li>}
                  </ul>
                </div> */}
              </div>
              {/* main_details */}

              <div className="additional_images">
                <Gallery
                  photos={prepareImageGallery(
                    itemOpen?.attributes?.portfolioPlayImageGallery?.data
                  )}
                />
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}
    </>
  );
};

const Portfolio = () => {
  const portfoliosPageData = usePortfolioPage();
  const setPortfolioPageData = usePortfolioPageUpdate();
  const updateError = useErrorUpdate();

  useEffect(() => {
    fetchPageData();
  }, []);

  const fetchPageData = async () => {
    try {
      const response = await axios.get(
        `${constants.API_URL}/api/portfolios?populate=%2A`
      );

      if (response.status === 200) {
        const data = _.sortBy(response.data.data, (item) => {
          return item.attributes.portfolioPlayDate.split(",")[0];
        }).reverse();
        setPortfolioPageData(data);
      }
    } catch (error) {
      updateError("Cannnot get data from server, please try again later");
    }
  };

  if (!portfoliosPageData) {
    return (
      <div className="tokyo_tm_home">
        <div className="home_content">
          <PropagateLoader />
        </div>
      </div>
    );
  }
  return (
    <>
      <Wrapper data={portfoliosPageData} />
    </>
  );
};

export default Portfolio;
