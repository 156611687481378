import React, { useEffect } from "react";
import Routes from "./router/Routes";
import AOS from "aos";
import "aos/dist/aos.css";
import { ErrorProvider } from "./contexts/errorContext";
import { HomePageProvider } from "./contexts/homePageContext";
import { AboutPageProvider } from "./contexts/aboutPageContext";
import { PortfolioPageProvider } from "./contexts/portfolioPageContext";

const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="tokyo_tm_all_wrap">
      <ErrorProvider>
        <HomePageProvider>
          <AboutPageProvider>
            <PortfolioPageProvider>
              <Routes />
            </PortfolioPageProvider>
          </AboutPageProvider>
        </HomePageProvider>
      </ErrorProvider>
    </div>
  );
};

export default App;
