import React, { useContext, useState } from 'react'

const AboutPageContext = React.createContext()
const SetAboutPageContext = React.createContext()

export function useAboutPage() {
  return useContext(AboutPageContext)
}

export function useAboutPageUpdate() {
  return useContext(SetAboutPageContext)
}

export function AboutPageProvider({ children }) {
  const [aboutPage, setAboutPage] = useState(null)

  return (
    <AboutPageContext.Provider value={aboutPage}>
      <SetAboutPageContext.Provider value={setAboutPage}>
        {children}
      </SetAboutPageContext.Provider>
    </AboutPageContext.Provider>
  )
}