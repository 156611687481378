import React, { useContext, useState } from 'react'

const PortfolioPageContext = React.createContext()
const SetPortfolioPageContext = React.createContext()

export function usePortfolioPage() {
  return useContext(PortfolioPageContext)
}

export function usePortfolioPageUpdate() {
  return useContext(SetPortfolioPageContext)
}

export function PortfolioPageProvider({ children }) {
  const [portfolioPage, setPortfolioPage] = useState(null)

  return (
    <PortfolioPageContext.Provider value={portfolioPage}>
      <SetPortfolioPageContext.Provider value={setPortfolioPage}>
        {children}
      </SetPortfolioPageContext.Provider>
    </PortfolioPageContext.Provider>
  )
}