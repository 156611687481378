import React, { useEffect } from "react";
import axios from "axios";
import Markdown from "markdown-to-jsx";
// import Modal from "react-modal";
import PropagateLoader from 'react-spinners/PropagateLoader'
import constants from "../config/constants";
import { useAboutPage, useAboutPageUpdate } from "../contexts/aboutPageContext";
import { useErrorUpdate } from "../contexts/errorContext";

import aboutPageImagePlaceholder from '../assets/2.jpg'

// Modal.setAppElement("#root");

const Wrapper = ({ data }) => {
  
  const mainImgUrl = data.attributes?.aboutPageMainImage?.data?.attributes?.url 
    ? `${constants.API_URL}${data.attributes.aboutPageMainImage.data.attributes.url}`
    : aboutPageImagePlaceholder

  const listOfSideEntries = Array.isArray(data.attributes.aboutPageEntries)
    ? data.attributes.aboutPageEntries
    : []
  
  const tagline = data.attributes?.aboutPageTagline ?? ''

  const mainText = data.attributes?.aboutPageMainText ?? ''

  const mapEntries = (array) => {
    try {
      const entries = array.map((item) => {
       return (
        <li key={item.id}>
          <p>
            <span>{item.aboutPageEntryTitle}:</span>{item.aboutPageEntryInfo}
          </p>
        </li>
       )
      })
      return entries
    } catch (error) {
      return
    }
  }

  return (
    <>
      <div className="tokyo_tm_about">
        <div className="about_image ">
          <img src={mainImgUrl} alt="about" />
        </div>
        {/* <div className="o-video">
          <iframe src="https://www.youtube.com/embed/0yW7w8F2TVA"></iframe>
        </div> */}
        {/* END ABOUT IMAGE */}
        <div className="description">
          <h3 className="name">{tagline}</h3>
          <div className="description_inner">
            <div className="left">
              <Markdown>
                {mainText}
              </Markdown>
              {/* END TOKYO BUTTON */}
            </div>
            {/* END LEFT */}
            {/* <div className="right">
              <ul>
                {mapEntries(listOfSideEntries)}
              </ul>
              
            </div> */}
            {/* END RIGHT */}
          </div>
          {/* END DESCRIPTION INNER */}
        </div>
      </div>
    </>
  );
}

const About = () => {
  // const [isOpen, setIsOpen] = useState(false);

  // function toggleModal() {
  //   setIsOpen(!isOpen);
  // }

  const aboutPageData = useAboutPage()
  const setAboutPageData = useAboutPageUpdate()
  const updateError = useErrorUpdate()


  useEffect(() => {
    fetchPageData()
  }, [])

  const fetchPageData = async () => {
    try {
      const response = await axios.get(`${constants.API_URL}/api/about?populate=%2A`)

      if (response.status === 200) {
        setAboutPageData(response.data)
      }
    } catch (error) {
      updateError('Cannnot get data from server, please try again later')
    }
  }

  if (!aboutPageData) {
    return (
      <div className="tokyo_tm_home">
        <div className="home_content">
          <PropagateLoader />
        </div>
      </div>
    )
  }
  return (
    <>
      <Wrapper data={aboutPageData.data} />
    </>
  )
  
};

export default About;
