import React from "react";

const Social = ({ links = [] }) => {
  return (
    <>
      <ul className="social">
        {links.map((val, i) => (
          <li key={i}>
            <a href={`${val.socialMediaPlatformLink}`} target="_blank" rel="noreferrer">
              <img
                className="svg"
                src={`/assets/img/svg/social/${val.socialMediaPlatformName}.svg`}
                alt="social"
              ></img>
            </a>
          </li>
        ))}
      </ul>
      {/* END SOCIAL */}
    </>
  );
};

export default Social;
