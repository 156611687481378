import React, { useEffect } from "react";
import axios from "axios";
import PropagateLoader from 'react-spinners/PropagateLoader'
import Social from "./Social";
import constants from "../config/constants";
import { useErrorUpdate } from "../contexts/errorContext";
import { useHomePage, useHomePageUpdate } from "../contexts/homePageContext";

const Wrapper = ({ data }) => {
  const tagline = data.attributes?.tagline
  const smLinks = data.attributes?.socialMediaLinks

  return (
    <>
      <div data-aos="fade-right" data-aos-duration="1200">
        <div className="tokyo_tm_home">
          <div className="home_content">
            <div className="avatar">
              <div
                className="image avatar_img"
                style={{
                  backgroundImage: `url(${constants.API_URL}${data.attributes.portrait.data.attributes.url})`,
                }}
              ></div>
              {/* END AVATAR IMAGE */}
            </div>
            {/* END AVATAR */}
            <div className="details">
              <h3 className="name">{data.attributes.name}</h3>
              <p className="job">
                {tagline ? tagline : ''}
              </p>
              {/* END JOB */}
              <Social links={smLinks}/>
            </div>
            {/* END DETAILS */}
          </div>
          {/* END HOME CONTENT */}
        </div>
        {/* END HOME */}
      </div>
    </>
  );
}

const Home = () => {
  const homePageData = useHomePage()
  const setHomePageData = useHomePageUpdate()
  const updateError = useErrorUpdate()


  useEffect(() => {
    fetchPageData()
  }, [])

  const fetchPageData = async () => {
    try {
      const responseHome = await axios.get(`${constants.API_URL}/api/home?populate=%2A`)

      if (responseHome.status === 200) {
        setHomePageData(responseHome.data)
      }
    } catch (error) {
      updateError('Cannnot get data from server, please try again later')
    }
  }

  if (!homePageData) {
    return (
      <div className="tokyo_tm_home">
        <div className="home_content">
          <PropagateLoader />
        </div>
      </div>
    )
  }

  return (
    <Wrapper data={homePageData.data}/>
  )
};

export default Home;
