import React, { useContext, useState } from 'react'

const ErrorContext = React.createContext()
const SetErrorContext = React.createContext()

export function useError() {
  return useContext(ErrorContext)
}

export function useErrorUpdate() {
  return useContext(SetErrorContext)
}

export function ErrorProvider({ children }) {
  const [error, setError] = useState(null)

  return (
    <ErrorContext.Provider value={error}>
      <SetErrorContext.Provider value={setError}>
        {children}
      </SetErrorContext.Provider>
    </ErrorContext.Provider>
  )
}