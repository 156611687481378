import React, { useContext, useState } from 'react'

const HomePageContext = React.createContext()
const SetHomePageContext = React.createContext()

export function useHomePage() {
  return useContext(HomePageContext)
}

export function useHomePageUpdate() {
  return useContext(SetHomePageContext)
}

export function HomePageProvider({ children }) {
  const [homePage, setHomePage] = useState(null)

  return (
    <HomePageContext.Provider value={homePage}>
      <SetHomePageContext.Provider value={setHomePage}>
        {children}
      </SetHomePageContext.Provider>
    </HomePageContext.Provider>
  )
}