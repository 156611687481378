import React from "react";
import { useState } from "react";
import axios from "axios";
import sanitize from "sanitize-html";
// import ReactMapGL from "react-map-gl";

const Contact = () => {
  // const [viewport, setViewport] = useState({
  //   latitude: 40.712776,
  //   longitude: -74.005974,
  //   zoom: 8,
  // });
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [isSuccess, setIsSuccess] = useState(null)

  async function sendMessage(e) {
    e.preventDefault();
    setIsSuccess('loading')

    const body = {
      data: {
        contactFormName: name,
        contactFormEmail: email,
        contactFormMessage: message
      }
    }

    try {
      await axios.post('https://olgalapina.eu/api/api/contact-form-entries', body)
      setName('')
      setEmail('')
      setMessage('')
      setIsSuccess(true)
    } catch (error) {
      setIsSuccess(false)
    }
  }

  return (
    <>
      <div className="tokyo_tm_contact">
        <div className="tokyo_tm_title">
          <div className="title_flex">
            <div className="left">
              <span>Contact</span>
              <h3>Get in Touch</h3>
            </div>
          </div>
        </div>
        {/* END TITLE */}

        {/* <div className="map_wrap">
          <div className="map">
            <ReactMapGL
              mapStyle={"mapbox://style/mapbox/dark-v9"}
              mapboxApiAccessToken="pk.eyJ1IjoiYmF5YXppZGgiLCJhIjoiY2tvemdwc3ByMDg1YzJubzQxcDR0cDR3dyJ9.s1zXEb5OPqgBDcmupj3GBA"
              {...viewport}
              onViewportChange={(nextViewport) => setViewport(nextViewport)}
            />
          </div>
        </div>
        MENU WRAP */}

        
        <div className="tokyo_tm_title">
          <div className="title_flex">
            <div className="left">
              <span><a href="mailto:contact@olgalapina.eu">Email: contact@olgalapina.eu</a></span>
            </div>
          </div>
        </div>
        

        <div className="fields">
          <form className="contact_form" onSubmit={sendMessage}>
            <div className="first">
              <ul>
                <li>
                  <input 
                    onChange={(e) => sanitize(setName(e.target.value))} 
                    type="text" 
                    name="name" 
                    placeholder="Name" 
                    value={name}
                  />
                </li>
                {/* END FIRST NAME */}

                <li>
                  <input 
                    onChange={(e) => sanitize(setEmail(e.target.value))} 
                    type="email" 
                    name="user_email" 
                    placeholder="Email" 
                    value={email} 
                  />
                </li>
                {/* END EMAIL */}

                <li>
                  <textarea 
                    onChange={(e) => sanitize(setMessage(e.target.value))} 
                    name="message" 
                    placeholder="Message" 
                    value={message}>
                  </textarea>
                </li>
                {/* END SUBJECT MESSAGE */}
              </ul>
            </div>
            <div className="tokyo_tm_button">
              <button type="submit" className="ib-button">
                Send Message
              </button>
              {isSuccess === 'loading' && <p className="tokyo_message_p">Sending...</p>}
              {isSuccess === true && <p className="tokyo_success_p">Your message was successfully sent</p>}
              {isSuccess === false && <p className="tokyo_failure_p">Something went wrong. Please refresh and try again</p>}
            </div>
            {/* END SUBMIT BUTTON */}
          </form>
          {/* END FORM */}
        </div>
        {/* END FIELDS */}
      </div>
      {/* END COTACT */}
    </>
  );
};

export default Contact;
